import React from 'react';

import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import Image from '../Image';

import fallbackLogo from '../../images/logo/undivided-logo.png';

const Logo = styled(Image)`
  display: block;
  width: 100%;
`;

const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    fileName: file(relativePath: { eq: "logo/undivided-logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 75) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`;

function SiteLogo() {
  return (
    <StaticQuery
      query={`${query}`} // String hack needed to fix https://github.com/birkir/gatsby-source-prismic-graphql/issues/70
    >
      {({ site, fileName: logoImage }) => (
        <Logo
          // fadeIn={false}
          fluid={logoImage?.childImageSharp?.fluid}
          fallback={{ url: fallbackLogo, alt: site.siteMetadata.title }}
          alt={site.siteMetadata.title}
        />
      )}
    </StaticQuery>
  );
}

export default SiteLogo;
